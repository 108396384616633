<template>
    <div class="reset-password">
        <loading v-if="loadingActive"/>
        <main class="flex items-center justify-center h-screen bg-gray-100">
            <p>Please verify your account with click on the verify link on welcome email.</p>
        </main>
    </div>
</template>

<script>
// import email from "../assets/Icons/envelope-regular.svg"
// import modal from "../components/Modal.vue"
import loading from "../components/Loading.vue"
// import firebase from "firebase/app"
import "firebase/auth";

export default{
    name: "NotVerified",
    components: {
      loading, // email, modal,
    },
    data() {
        return {
            // email: "",
            // modalActive: false,
            // modalMessage: "",
            loadingActive: null,
            timer: 0,
        };
    },
    created () {
        this.timer = setInterval(this.reload, 10000 );
    },
    methods: {
        reload () {
            location.reload();
        }
    //     resetPassword() {
    //         this.loadingActive = true;
    //         firebase.auth().sendPasswordResetEmail(this.email)
    //         .then(() => {
    //             this.modalMessage = "If your account exist, you will receive a password reset email!";
    //             this.loadingActive = false;
    //             this.modalActive = true;
    //         })
    //         .catch((error) => {
    //             this.loadingActive = false;
    //             this.modalMessage = error.message;
    //             this.modalActive = true;
    //         });
    //     },
    //     closeModal() {
    //         this.modalActive = !this.modalActive;
    //         this.email = "";
    //     }
    }
}
</script>

<style lang="scss" scoped>
.reset-password {
    position: relative;
    .form-wrap {
        .reset {
            h2 {
                margin-bottom: 8px;
            }

            p {
                text-align: center;
                margin-bottom: 32px;
            }
        }
    }
}
</style>