// Your web app's Firebase configuration
import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyDnTsy_Z84-O0xBtoOComlRCCJ9MUa8zMo",
    authDomain: "tosuccess-369.firebaseapp.com",
    projectId: "tosuccess-369",
    storageBucket: "tosuccess-369.appspot.com",
    messagingSenderId: "359395687463",
    appId: "1:359395687463:web:84554086f48a3df6416202",
    measurementId: "G-2JGM0DYTCF"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { timestamp };
export default firebaseApp.firestore();