<template>
    <div class="goal-card-wrap">
        <div class="goal-cards container">
            <div class="toggle-edit">
                <span>Toggle Editing Post</span>
                <input type="checkbox" v-model="editPost" />
            </div>
            <GoalCard v-bind:post="post" v-for="(post, index) in sampleGoalCards" :key="index" />
        </div>
    </div>
</template>

<script>
import GoalCard from "../components/GoalCard.vue";

export default{
    name: "goals",
    components: {
        GoalCard
    },
    computed: {
        sampleGoalCards() {
            return this.$store.state.sampleGoalCards;
        },
        editPost: {
            get() {
                return this.$store.state.editPost;
            },
            set(payload) {
                this.$store.commit("toggleEditPost", payload);
            }
        },
    },
    beforeDestroy() {
        this.$store.commit("toggleEditPost", false);
    },
}
</script>

<style lang="scss" scoped>

.goal-cards {
    position: relative;

    .toggle-edit {
        display: flex;
        align-items: center;
        position: absolute;
        top: -70px;
        right: 0;

        span {
            margin-right: 16px;
        }

        input[type="checkbox"] {
            position: relative;
            border: none;
            -webkit-appearance: none;
            background: #fff;
            outline: none;
            width: 80px;
            height: 30px;
            border-radius: 20px;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);    
        }

        input[type="checkbox"]:before {
            content: "";
            position: absolute;
            width: 30px;
            height: 30px;
            border-radius: 20px;
            top: 0;
            left: 0;
            background: #303030;
            transform: scale(1.1);
            transition: 750ms ease all;
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);    
        }

        input:checked[type="checkbox"]:before {
            background: #fff;
            left: 52px;
        }
    }

}



</style>