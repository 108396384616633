import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Goals from "../views/Goals.vue";
import Login from "../views/Login.vue";
import NotVerified from "../views/NotVerified.vue";
import Register from "../views/Register.vue";
import ForgotPassword from "../views/ForgotPassword.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Home"
    }
  },
  {
    path: "/goals",
    name: 'Goals',
    component: Goals,
    meta: {
      title: "Goals",
    }
  },
  {
    path: "/login",
    name: 'Login',
    component: Login,
    meta: {
      title: "Login",
    }
  },
  {
    path: "/verify",
    name: 'NotVerified',
    component: NotVerified,
    meta: {
      title: "Not Verified",
    }
  },
  {
    path: "/register",
    name: 'Register',
    component: Register,
    meta: {
      title: "Register",
    }
  },
  {
    path: "/forgot-password",
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: "Forgot Password",
    }
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | 9tosuccess`;
  next();
})
export default router;
