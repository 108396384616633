<template>
   <div class="home">
       <!-- <GoalPost v-if="!user" v-bind:post="welcomeScreen"/> -->
       <!-- <GoalPost v-bind:post="post" v-for="(post, index) in sampleGoalPost" :key="index"/> -->
       <div class="goal-card-wrap">
            <div class="container">
                <h3>My Goals</h3>
                <div class="goal-cards">
                    <GoalCard v-bind:post="post" v-for="(post, index) in sampleGoalCards" :key="index" />
                </div>
            </div>
        </div>
        <div v-if="!user" class="updates">
            <div class="container">
                <h2>never miss a post. Register for a free account today</h2>
                <router-link class="router-button" to="#">
                    Register for 9tosuccess<Arrow class="arrow arrow-light"/>                
                </router-link>
            </div>

        </div>
   </div> 
</template>

<script>
// import GoalPost from "../components/GoalPost.vue";
import GoalCard from "../components/GoalCard.vue";
import Arrow from "../assets/Icons/arrow-right-light.svg"

export default {
    name: "home",
    components: {
        // GoalPost,
        GoalCard, 
        Arrow,
    },
    data() {
        return {
            welcomeScreen: {
                title: "Welcome",
                goalPost: 
                "Weekly goal articles with all things",
                welcomeScreen: true,
                photo: "coding",
            }, sampleGoalPost: [
                {
                    title: "This is a Filler Title!",
                    goalHTML: "This is a filler goal post title!",
                    goalCoverPhoto: "beautiful-stories",
                },
                {
                    title: "This is a Filler Title!",
                    goalHTML: "This is a filler goal post title!",
                    goalCoverPhoto: "designed-for-everyone"
                },
            ],
        };
    },
    methods: {},
    computed: {
        sampleGoalCards() {
            return this.$store.state.sampleGoalCards;
        },
        user() {
            return this.$store.state.user;
        }
    }
}
</script>

<style lang="scss" scoped>
.goal-card-wrap {
    h3{
        font-weight: 300;
        font-size: 28px;
        margin-bottom: 32px;
    }
}

.updates {
    .container {
        padding: 100px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @media(min-width: 800px) {
            padding: 125px 25px;
            flex-direction: row;
        }
    
        .router-button {
            display: flex;
            // flex-direction: column;
            text-decoration: none;
            font-size: 14px;
            @media(min-width: 800px) {
                margin-left: auto;
            }
        }

        h2 {
            font-weight: 300;
            font-size: 32px;
            max-width: 425px;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            @media(min-width: 800px) {
                text-align: initial;
                font-size: 40px;
            }
        }
    }
}
</style>