<template>
        <div class="form-wrap">
        <form class="register">
            <p class="login-register">
                Already have an account?
                <router-link class="router-link" :to="{name: 'Login' }">Login</router-link>
            </p>
            <h2>Create Your 9tosuccess Account</h2>
            <div class="inputs">
                <div class="input">
                    <input type="text" placeholder="First Name" v-model="firstName"/>
                    <user class="icon"/>
                </div>
                <div class="input">
                    <input type="text" placeholder="Last Name" v-model="lastName"/>
                    <user class="icon"/>
                </div>
                <div class="input">
                    <input type="text" placeholder="Username" v-model="username"/>
                    <user class="icon"/>
                </div>
                <div class="input">
                    <input type="text" placeholder="Email" v-model="email"/>
                    <email class="icon"/>
                </div>
                <div class="input">
                    <input type="password" placeholder="Password" v-model="password"/>
                    <password class="icon"/>
                </div>
            </div>
            <div v-show="error" class="error">
                {{ this.errorMsg }}
            </div>
            <button v-on:click.prevent="register" class="button">Register/ Sign Up</button>
            <div class="angle">
            </div>
        </form>
        <div class="background"></div>
    </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg"
import password from "../assets/Icons/lock-alt-solid.svg"
import user from "../assets/Icons/user-alt-light.svg"
import firebase from "firebase/app"
import "firebase/auth"
import db from "../firebase/firebaseInit.js"

export default{
    name: "Register",
    components: {
        email, password, user,
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            password: "",
            error: null,
            errorMsg: "",
        };
    },
    methods: {
        async register() {
            if(
                this.firstName !== "" &&
                this.lastName !== "" &&
                this.password !== "" &&
                this.email !== "" &&
                this.username !== "" 
            )  {
                this.error = false;
                this.errorMsg = "";
                const firebaseAuth = await firebase.auth();
                const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
                await firebaseAuth.currentUser.sendEmailVerification();
                const result = await createUser;
                const dataBase = db.collection("users").doc(result.user.uid);
                await dataBase.set({
                    firstName: this.firstName,
                    lastName: this.lastName,
                    username: this.username,
                    email: this.email
                });
                this.$router.push({name: "NotVerified"}).catch(()=>{});
                return;
            } 
            this.error = true;
            this.errorMsg = "Please fill out all the fields!"
        }
    }
}
</script>

<style lang="scss" scoped>

.register {
    h2 {
        max-width: 350px;

    }
}
</style>